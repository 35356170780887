import axios from "axios";

export const getLanguageCode = () => {
  let languageCode = 'en';

  if (window.localStorage.getItem('langCode')) {
    languageCode = window.localStorage.getItem('langCode');
  } else {
    languageCode = navigator.language.split('-')[0];
  }

  return languageCode;
}

/*** Footer 언어 선택 ***/
export function dropdownOpen(elem) {
  const dropdownWrap = elem.closest('.dropdown');

  if (!dropdownWrap.classList.contains('ui-active')) {
    dropdownWrap.classList.add('ui-active')
  } else {
    dropdownWrap.classList.remove('ui-active')
  }
}

export function dropdownClose() {
  const dropdownWrap = document.querySelector('.dropdown');
  dropdownWrap.classList.remove('ui-active')
}

export function gnbClose () {
  const body = document.querySelector('body');
  const navigator = document.querySelector('.navigator');
  const subPanel = document.querySelector('.gnb-sub-panel');

  gnbLinkInactive()
  body.classList.remove('fixed');
  navigator.classList.remove('ui-active');
  subPanel.classList.remove('ui-active');
}

export const geoIpResult = async () => {
  try {
    const baseUrl = process.env.REACT_APP_ENV === 'development'
      ? 'https://dev-api-internal-service.wjtb.kr'
      : 'https://api-internal-service.mathpid.com';

    const result = await axios.post(`${baseUrl}/api/geoip`, {})
      .then((response) => {
        if (response.data?.data && response.data.data?.country_cd) {
          return response.data.data?.country_cd;
        }
      })
      .catch((error) => {
        return 'error'
        console.log("#### error: ", error);
      });

    return result;
  } catch (e) {
    return ''
    console.log("##### geoip error: ", e);
  }
}

// gnb 버튼 비활성
function gnbLinkInactive() {
  const gnbList = document.querySelector('.gnb-list')
  const gnbListItem = gnbList.querySelectorAll('li');

  gnbListItem.forEach((item) => {
    const dep2 = item.querySelector('.gnb-sub-panel')
    // item.classList.remove('ui-active');
    if (dep2) {
      dep2.classList.remove('ui-active');
    }
  });
}