import React, {useCallback, useEffect, useState} from 'react';
import axios from "axios";
import {geoIpResult} from "../../../lib/util";
import {useAppContext} from "../../../App";

function MathpidContact(props) {
  const { countryCd } = useAppContext();

  const handleMovePage = () => {
    const baseUrl = process.env.REACT_APP_ENV === 'development'
      ? 'https://dev-web-public-service-lms.wjtb.kr'
      : 'https://lms.mathpid.com';

    window.open(`${baseUrl}/estimate/in_request`, '_blank');
  }

  return (
    <main className="container">
      <section className="top-section-contact">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">Contact Us</h2>
            <p className="desc">Complete the form below, and our Mathpid team will be in touch promptly!</p>
            {countryCd === 'IN' && (
              <a
                className="desc link"
                onClick={handleMovePage}
              >
                If you're from India, click here to book a 1-on-1 session with our dedicated
                Indian partner!
              </a>
            )}

          </div>
        </div>
      </section>
      <section className="section no-gutter">
        <div className="iframe-wrap">
        <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSckaRif-WsBIq-wmYa3zj9u8nI46yrjMFpxSQGyj6shuE5eog/viewform?embedded=true"
            width="100%" height="100%" frameBorder="0" marginHeight="0" marginWidth="0"></iframe>
        </div>
      </section>
    </main>
  );
}

export default MathpidContact;