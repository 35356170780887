import { Routes, Route, useNavigate } from 'react-router-dom';
import {createContext, useCallback, useContext, useEffect, useRef, useState} from "react";
import MathpidHeader from "./component/mathpid_header";
import MathpidFooter from "./component/mathpid_footer";
import MainPage from "./pages/main/main_page";
import {geoIpResult, getLanguageCode, gnbClose} from "./lib/util";
import EducatorsPage from "./pages/educators/EducatorsPage";
import LearnersPage from "./pages/learners/LearnersPage";
import Impact_page from "./pages/impact/impact_page";
import ImpactPage from "./pages/impact/impact_page";
import EtcPage from "./pages/etc/EtcPage";

const AppContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}

function App() {
  const navigate = useNavigate();
  const [langCd, setLangCd] = useState('en');
  const [countryCd, setCountCd] = useState('');

  useEffect(() => {
    geoipAPICall();
    const script = document.createElement('script');

    script.src = `${process.env.PUBLIC_URL}/js/common_ui.js`;
    script.async = true;
    script.onload = () => {
      // common.js가 로드되고 실행된 후 추가 작업이 필요하다면 여기에 작성
    };
    document.body.appendChild(script);


    if (window.location.pathname === '/ko') {
      setLangCd('ko');
    } else {
      setLangCd(getLanguageCode());
    }

    return () => {
      // 컴포넌트가 언마운트될 때 스크립트 제거
      document.body.removeChild(script);
    };

  }, []); // 빈 배열을 넣어 컴포넌트 마운트 시 한 번만 실행

  const geoipAPICall = useCallback(async ( )=> {
    const result = await geoIpResult();
    console.log({ result });
    setCountCd(result);

  }, []);

  const handleChangeLangCd = (value) => {
    let code = getLanguageCode();

    if (value) code = value;
    setLangCd(code);
    if (window.location.pathname === '/ko') {
      window.location.href = "/";
    } else {
      window.location.reload();
    }

    window.scrollTo(0, 0);
    window.localStorage.setItem('langCode', code);
  }

  const handlePage = (path) => {
    window.scrollTo(0, 0);
    gnbClose();
    navigate(path);
  }


  return (
    <div className="wrap">
      <AppContext.Provider value={{
        langCd: langCd,
        countryCd: countryCd,
        handlePage,
        handleChangeLangCd,
      }}>
        <MathpidHeader />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/main" element={<MainPage />} />
          <Route path="/ko" element={<MainPage />} />
          <Route path="/educators" element={<EducatorsPage />} />
          <Route path="/learners" element={<LearnersPage />} />
        </Routes>
        <ImpactPage />
        <EtcPage />
        <MathpidFooter />
      </AppContext.Provider>
    </div>
  );
}

export default App;
