import React, {useCallback, useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import {useAppContext} from "../App";
import clsx from "clsx";
import {geoIpResult} from "../lib/util";

function MathpidHeader(props) {
  const {langCd, countryCd, handlePage} = useAppContext();
  const { pathname: path } = useLocation();
  const [subMenu, setSubMenu] = useState(false);


  const handleMovePage = () => {
    const baseUrl = process.env.REACT_APP_ENV === 'development'
      ? 'https://dev-web-public-service-lms.wjtb.kr'
      : 'https://lms.mathpid.com';

    const path = countryCd === 'IN' ? '/estimate/in_request' : '/estimate/request';

    window.open(`${baseUrl}${path}`, '_blank');
  }

  if (!langCd) return <></>;

  return (
    <>
      {
        langCd === 'ko'
          ? (
            <header className="header" lang="ko">
              <div className="top-banner">
                <div className="inner">
                  <p className="message"><a href="http://class.mathpid.com" target="_blank">Meet Mathpid for PC 🖥️ Explore now
                    at class.mathpid.com</a></p>
                </div>
              </div>
              <div className="header-inner">
                <div className="mobile-head">
                  <h1 className="logo">
                    <a onClick={() => handlePage('main')}><img
                      src={`${process.env.PUBLIC_URL}/images/logos/logo_mathpid.png`}
                      alt="Mathpid"/></a>
                  </h1>
                  <button type="button" className="btn-gnb"><span className="blind">Memu</span></button>
                </div>
                <nav className="navigator">
                  <div className="inner">
                    <div className="nav-head">
                      <div className="nav-head-logo">
                        <a onClick={() => handlePage('main')}><img
                          src={`${process.env.PUBLIC_URL}images/logos/logo_mathpid.png`} alt="Mathpid"/></a></div>
                      <button type="button" className="btn-gnb-close"><span className="blind">Close</span></button>
                    </div>
                    <div className="gnb">
                      <ul className="gnb-list">
                        <li
                          className={clsx(path === '/educators' && 'ui-active')}
                        >
                          <a onClick={() => handlePage('educators')} className="gnb-link"
                             data-page="educators"><span>선생님용</span></a>
                        </li>
                        <li
                          className={clsx(path === '/learners' && 'ui-active')}
                        >
                          <a onClick={() => handlePage('learners')} className="gnb-link"
                             data-page="learners"><span>학생용</span></a>
                        </li>
                        <li
                          className={clsx(subMenu && 'ui-active')}
                        >
                          <a
                            className="gnb-link"
                            onClick={() => {setSubMenu(!subMenu)}}
                          >
                            <span>영향력</span>
                            <i className="icon-gnb-arrow"></i>
                          </a>
                          <div className="gnb-sub-panel">
                            <ul className="sub-list">
                              <li>
                                <a onClick={() => handlePage('impact')} data-page="impact"><span>영향력</span></a>
                              </li>
                              <li>
                                <a onClick={() => handlePage('impact_features')}
                                   data-page="impact"><span>매쓰피드 기능</span></a></li>
                              <li>
                                <a onClick={() => handlePage('impact_reviews')}
                                   data-page="impact"><span>리뷰</span></a></li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a href="https://company.wjthinkbig.com/index.do" className="gnb-link"
                             target="_blank"><span>회사소개</span></a>
                        </li>
                        <li
                          className={clsx(path === '/contact' && 'ui-active')}
                        >
                          <a onClick={() => handlePage('contact')} className="gnb-link"
                             data-page="contact"><span>문의</span></a>
                        </li>
                      </ul>
                    </div>
                    <div className="util">
                      <ul className="util-list">
                        <li>
                          <a
                            href="https://class.mathpid.com/member/createAccount?type=lms"
                            className="btn-basic-sm btn-primary"
                            target="_blank"
                          >
                            <span>무료 체험 계정 신청하기</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://lms.mathpid.com/estimate/request" className="btn-basic-sm btn-point"
                             target="_blank"> <span>맞춤형 견적 요청</span></a></li>
                        <li>
                          <a href="https://class.mathpid.com/member/login" className="btn-basic-sm btn-secondary"
                             target="_blank"><span>로그인</span></a></li>
                      </ul>
                    </div>
                  </div>
                </nav>
                <div className="gnb-dim"></div>
              </div>
            </header>
          )
          : (
            <header className="header" lang="en">
              <div className="top-banner">
                <div className="inner">
                  <p className="message">
                    <a href="http://class.mathpid.com" target="_blank">Meet Mathpid for PC 🖥️ Explore now
                      at class.mathpid.com</a></p>
                </div>
              </div>
              <div className="header-inner">
                <div className="mobile-head">
                  <h1 className="logo">
                    <a onClick={() => handlePage('main')}><img
                      src={`${process.env.PUBLIC_URL}images/logos/logo_mathpid.png`} alt="Mathpid"/></a>
                  </h1>
                  <button type="button" className="btn-gnb"><span className="blind">Memu</span></button>
                </div>
                <nav className="navigator">
                  <div className="inner">
                    <div className="nav-head">
                      <div className="nav-head-logo">
                        <a onClick={() => handlePage('main')}><img
                          src={`${process.env.PUBLIC_URL}images/logos/logo_mathpid.png`} alt="Mathpid"/></a></div>
                      <button type="button" className="btn-gnb-close"><span className="blind">Close</span></button>
                    </div>
                    <div className="gnb">
                      <ul className="gnb-list">
                        <li>
                          <a onClick={() => handlePage('educators')} className="gnb-link"
                             data-page="educators"><span>For Educators</span></a>
                        </li>
                        <li
                        >
                          <a onClick={() => handlePage('learners')} className="gnb-link"
                             data-page="learners"><span>For Learners</span></a>
                        </li>
                        <li
                          className={clsx(subMenu && 'ui-active')}
                        >
                          <a onClick={() => {setSubMenu(!subMenu)}} className="gnb-link"><span>Impact</span><i
                            className="icon-gnb-arrow"></i></a>
                          <div className="gnb-sub-panel">
                            <ul className="sub-list">
                              <li>
                                <a onClick={() => handlePage('impact')} data-page="impact"><span>Impact</span></a>
                              </li>
                              <li>
                                <a onClick={() => handlePage('impact_features')} data-page="impact"><span>Discover Mathpid Features</span></a>
                              </li>
                              <li>
                                <a onClick={() => handlePage('impact_reviews')}
                                   data-page="impact"><span>Reviews &amp; Testimonials</span></a></li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a href="https://company.wjthinkbig.com/en/index.do" className="gnb-link" target="_blank"><span>About Us</span></a>
                        </li>
                        <li>
                          <a onClick={() => handlePage('contact')} className="gnb-link" data-page="contact"><span>Contact Us</span></a>
                        </li>
                      </ul>
                    </div>
                    <div className="util">
                      <ul className="util-list">
                        <li>
                          <a href="https://class.mathpid.com/member/createAccount?type=lms"
                             className="btn-basic-sm btn-primary" target="_blank"><span>Try It Free</span></a></li>
                        <li>
                          <a onClick={handleMovePage} className="btn-basic-sm btn-point"
                             target="_blank"> <span>Get a quote</span></a></li>
                        <li>
                          <a href="https://class.mathpid.com/member/login" className="btn-basic-sm btn-secondary"
                             target="_blank"><span>Login</span></a></li>
                      </ul>
                    </div>
                  </div>
                </nav>
                <div className="gnb-dim"></div>
              </div>
            </header>
          )
      }
    </>
  )
}

export default MathpidHeader;